@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes slideLeft {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-20%);
  }
}

@keyframes slideRight {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(20%);
  }
}

.animate-slide-right{
  animation: slideRight 20s linear infinite;
}

.animate-slide-left {
  animation: slideLeft 20s linear infinite;
}


.animate-slide-up {
  animation: slide-up 2s ease forwards;
}

@keyframes slide-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: -translateY(100%);
    opacity: 1;
  }
}

.dark {
  --background: #000000;
  --textColor: #ffffff;
  --secondBackground: #1F1F1F;
  --secondaryTextColor: #9D9D9D;
  --thirdBackground: #B9B4C7;
  --hoverBackground: #1F1F1F;
}

.light {
  --background: #ffffff;
  --textColor: #000000;
  --secondBackground: #FFF3EA;
  --secondaryTextColor: #72687B;
  --thirdBackground: #FFFFFF;
  --hoverBackground: #FFF5EF;
}

:root {
  --transition-duration: 1s;
}

body,
.bg-background {
  transition: background-color var(--transition-duration), color var(--transition-duration);
}